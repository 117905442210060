<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
            <section class="fixed mypage-contents-fixed">
                <div class="container">
                    <div class="row">
                      <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData" :validation-errors="deleteAccountValidationErrors"></MyPageContents>
                      <div class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
                        <div class="form-title-group">
                          <div class="menu-active-mark" style="margin-top: 10px;"/>
                          <div class="form-title"><h1>ユーザー名変更</h1></div>
                        </div>
                        <div class="menu-bar"/>
                        <p class="form-text">ユーザー名を変更することが可能です。ユーザー名はフルネームでの登録を推奨しております。なお、ユーザー名は公開されることはございません。</p>
                          <div v-if="hasValidationError" class="error-area">
                              <p v-for="item in allValidationError" :key="item.validationText">
                                  ・{{item.validationText}}
                              </p>
                          </div>
                          <form @submit.prevent="send">
                              <div class="form-group edit-input">
                                  <p v-if="isChanged" class="result-text-success">ユーザー名の変更が完了しました。</p>
                                  <label style="width: 100%;">
                                      <input type="text" class="form-control" placeholder="ユーザー名" v-model="userName">
                                  </label>
                              </div>
                              <button type="submit" class="btn-md full-width pop-login form-button" :disabled="userName.length === 0">ユーザー名を変更する</button>
                          </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    </div>
</template>
<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MyPageContents from "@/views/components/MyPageContents";
    import MyPageMixIn from "@/common/mypage_mixin";
    import Vue from "vue";

    export default {
        name: "ChangeName",
        components: {Footer, Header, MyPageContents},
        mixins: [MyPageMixIn],
        data: function() {
            return {
                userName: "",
                isChanged: false
            }
        },
        watch: {
            userData: function () {
                if (!this.userData) {
                    this.userName = "";
                }
                this.userName = this.userData.name;
            }
        },
        methods: {
            async send () {
                const response = await this.postApi("user/changeUserName", {
                    userName: this.userName,
                });
                if (response) {
                    this.isChanged = true;
                    Vue.prototype.$user = response.data.user;
                    this.userData = response.data.user;
                } else {
                    this.isChanged = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>
